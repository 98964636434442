import Vue from 'vue'
import vuetify from './plugins/vuetify'
import store from './store'
import router from './router'
import i18n from './plugins/i18n'
import App from './components/App'
import '~/../sass/app.scss'

import './plugins'
import './components'

Vue.config.productionTip = false

/* eslint-disable no-new */
new Vue({
  el: '#app',
  i18n,
  store,
  router,
  vuetify,
  render: h => h(App)
})
