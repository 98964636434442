var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{attrs:{"id":"chat","value":_vm.value,"app":"","right":"","width":"300"},on:{"input":_vm.changeDrawerState},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-form',{staticClass:"pa-2",on:{"submit":function($event){$event.preventDefault();return _vm.sendMessage($event)}},model:{value:(_vm.formIsValid),callback:function ($$v) {_vm.formIsValid=$$v},expression:"formIsValid"}},[_c('v-text-field',{attrs:{"dense":"","flat":"","full-width":"","placeholder":_vm.$t('Message'),"append-outer-icon":"mdi-send","readonly":_vm.form.busy || !_vm.echo,"loading":_vm.form.busy,"counter":_vm.maxLength,"rules":[function (v) { return _vm.validationMaxLength(v, _vm.maxLength); }],"error":_vm.form.errors.has('message'),"error-messages":_vm.form.errors.get('message')},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return _vm._l((_vm.recipients),function(recipient,index){return _c('v-chip',{key:("user-" + (recipient.name)),attrs:{"label":"","pill":"","small":"","close":true},on:{"click:close":function($event){return _vm.removeRecipient(index)}}},[_vm._v("\n            "+_vm._s(recipient.name)+"\n          ")])})},proxy:true},{key:"append-outer",fn:function(){return [_c('v-btn',{attrs:{"type":"submit","icon":"","disabled":_vm.form.busy || !_vm.room || !_vm.form.message || !_vm.formIsValid}},[_c('v-icon',[_vm._v("mdi-send")])],1)]},proxy:true}]),model:{value:(_vm.form.message),callback:function ($$v) {_vm.$set(_vm.form, "message", $$v)},expression:"form.message"}})],1)]},proxy:true}])},[_c('div',{staticClass:"px-2 py-4"},[_c('h4',{staticClass:"title"},[_vm._v("\n      "+_vm._s(_vm.$t('Chat'))+"\n      "),_c('span',{staticClass:"float-right"},[(_vm.rooms.length > 1)?_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-filter-variant")])],1)]}}],null,false,3374273757),model:{value:(_vm.roomMenu),callback:function ($$v) {_vm.roomMenu=$$v},expression:"roomMenu"}},[_vm._v(" "),_c('v-card',[_c('v-subheader',{staticClass:"text-uppercase"},[_vm._v("\n              "+_vm._s(_vm.$t('Rooms'))+"\n            ")]),_vm._v(" "),_c('v-select',{staticClass:"px-5",attrs:{"items":_vm.rooms,"item-text":"name","item-value":"id"},on:{"change":function($event){_vm.roomMenu = false}},model:{value:(_vm.room),callback:function ($$v) {_vm.room=$$v},expression:"room"}})],1)],1):_vm._e(),_vm._v(" "),_c('span',{staticClass:"font-weight-regular"},[_c('v-icon',[_vm._v("mdi-account-multiple")]),_vm._v("\n          "+_vm._s(_vm.usersCount)+"\n        ")],1)],1)])]),_vm._v(" "),_c('div',{ref:"messages",staticClass:"chat-messages-container overflow-y-auto"},[_c('v-list',{staticClass:"pa-0",attrs:{"two-line":""}},[_c('transition-group',{attrs:{"name":"slide-x-transition","tag":"div"}},_vm._l((_vm.messages),function(msg,index){return _c('v-hover',{key:("message-" + index),scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-list-item',{class:{ 'grey darken-2': hover }},[_c('v-list-item-avatar',{staticClass:"align-self-start",attrs:{"size":"30"}},[_c('v-img',{attrs:{"src":msg.user.avatar_url}})],1),_vm._v(" "),_c('v-list-item-content',{staticClass:"align-self-start"},[_c('v-list-item-title',{staticClass:"subtitle-2"},[_c('user-profile-modal',{attrs:{"user":msg.user},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"text--primary link"},on),[_vm._v("\n                      "+_vm._s(msg.user.name)+"\n                    ")])]}}],null,true)}),_vm._v(" "),(_vm.user.id !== msg.user.id)?_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(hover),expression:"hover"}],staticClass:"float-right",attrs:{"icon":"","x-small":""},on:{"click":function($event){return _vm.addRecipient(msg.user)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("\n                    mdi-reply\n                  ")])],1):_vm._e()],1),_vm._v(" "),_c('p',{staticClass:"text--secondary mb-0"},[(msg.recipients)?_vm._l((msg.recipients),function(recipient){return _c('user-profile-modal',{key:("recipient-" + (recipient.name)),attrs:{"user":recipient},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._g({staticClass:"mb-1 mr-1",attrs:{"label":"","link":"","small":""}},on),[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("\n                          mdi-account\n                        ")]),_vm._v("\n                        "+_vm._s(recipient.name)+"\n                      ")],1)]}}],null,true)})}):_vm._e(),_vm._v("\n                "+_vm._s(msg.message)+"\n              ")],2)],1)],1)]}}],null,true)})}),1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }